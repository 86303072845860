import React,{Component} from 'react';
import { MDBInput } from 'mdbreact';
import { Controlled as CodeMirror4 } from 'react-codemirror2';
import $ from 'jquery/dist/jquery.js';
import atclogo from '../../images/loader.png';
import whoAmILogo from '../../images/quiz-icon.png';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/lucario.css';
import 'codemirror/mode/htmlmixed/htmlmixed';





class editorQuizBAssessments extends Component{ 
   constructor(props){
   	super(props)

   	this.state = {
       taskRef:this.props.taskRef, 
       html:'',
       codeSnippet:"loading",
       question:"",
       optionA:"",
       optionB:"", 
       optionC:"", 
       visual:"",
       answer:"",
       taskByteValue:"",
       studentResponse:""

   	}

 this.fetchQuizResource = this.fetchQuizResource.bind(this);
 this.quizResponseUpdate = this.quizResponseUpdate.bind(this);
 this.textReader = this.textReader.bind(this);
 

   }

  async componentDidUpdate(prevState){  
  
 
  }


  async componentDidMount(){
    
  	this.props.taskStatus(false);
  	await this.fetchQuizResource(); 
  	console.log(this.state.userAnswer);
    this.props.quizAnswer(this.state.answer);
    this.props.byteState(this.state.taskByteValue);
    //this.responsiveClass();

  }


  updateStudentResponse = (event) => { 
    this.setState({
          studentResponse: event.target.value
     })
}



async quizResponseUpdate(event){
    alert(this.state.studentResponse)
     
    await  this.props.quizResponse(this.state.studentResponse)
           this.props.validateQuiz()



     
}

async fetchQuizResource(){
     alert('ran 2')
     const requestResource = await fetch(`https://atcbrain-7de683f08325.herokuapp.com/learning-engine/resource-api/v1/${this.state.taskRef}/quizAssessmentTask`, {
      method:"POST", 
      headers:{
      	"Content-Type" : "application-json"
      },
      mode:"cors",
      credentials:"include"

     })
     .then(response => response.json())
     .then(response => {console.log(response)

          this.setState({ 
            question:response.task[0].question,
            optionA: response.task[0].optionA,
            optionB: response.task[0].optionB,
            optionC: response.task[0].optionC, 
            visual:  response.task[0].visual,
            tag: response.task[0].tag,
            assessmentRef: response.task[0].assessmentRef,
            codeSnippet: response.task[0].codeSnippet,
            bookName: response.task[0].bookName   
          })



     })
}

async textReader(){

  console.log('spoke')

  $.when(window.responsiveVoice.speak(`Analyse This`))
  .then(window.responsiveVoice.speak(`${this.state.question}`))
  .then(window.responsiveVoice.speak(`is it ${this.state.optionA}`))
  .then(window.responsiveVoice.speak(`or ${this.state.optionB}`))
  .then(window.responsiveVoice.speak(`or ${this.state.optionC}`))
             
}






runCode = () => {
    const {html} = this.state;

    const iframe = this.refs.iframe;
    const document = iframe.contentDocument;
    const documentContents = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta http-equiv="X-UA-Compatible" content="ie=edge">
        <title>Document</title>
        <style>
       
        </style>
      </head>
      <body>
        ${html}
      </body>
      </html>
    `;

    document.open();
    document.write(documentContents);
    document.close();
  };










render() {


    const { html } = this.state;
    const codeMirrorOptions = {
      mode: 'htmlmixed', 
      tabMode: 'indent',
      theme: 'lucario',
      value: this.state.codeSnippet,
      lineNumbers: true,
      scrollbarStyle: null,
      lineWrapping: true,
      smartIndent: true,
      electricChars: true,
      autofocus: true,
      lineSeparator:"    ",
      readOnly: true
      
    };

const divStyle = {
      backgroundImage: 'url("../../images/hero-large.png")',
      WebkitTransition: 'all', // note the capital 'W' here
      msTransition: 'all' // 'ms' is the only lowercase vendor prefix
};

const optionsArray= [this.state.optionA, this.state.optionB, this.state.optionC]

const optionsList = optionsArray.map(

	options => <div className="col-md-3 text-center white-text header-font"> <MDBInput label={options} filled type="radio" name="opt" value={options} />  </div>

	)

	return(
  <div className="bottom-right-curve top-right-curve black-background" id="editor-properties" > 
   <div className="row standard-padding-tb-3 black-background top-right-curve" id="editor-instructions"> 
             <div className="col-12 col-md-2 text-center"> 
             <figure> 
             <button onClick={this.textReader} className="azure-bg" data-toggle="tooltip" data-placement="right" title="Click for voice">
              <img src={whoAmILogo} width="60"/> 
              </button>
              <figcaption className="">
               <p className="body-font yellow-background tiny-font"> Code Analysis</p> 
               <p className="accent-font tiny-font white-text mobile-text"> Complete the tasks by following the instructions </p>
              </figcaption> 
             </figure>
             </div>      

             <div className="col-md-10 standard-margin-top-quiz">
              <div className="row">

               <div className="col-12"> 
                     <h3 className="header-font white-text"> Riddle </h3> 
             <p className="header-font white-text mobile-text"> {this.state.question} </p>
         

               </div>
               <div className="col-12">
               <div className="row">
                 <div className="col-md-9">
                 <div className="form-group">
                      <label className="body-font white-text"> Type your answer </label>
                        <input type="text" value={this.state.studentResponse} onChange={this.updateStudentResponse} className="form-control input-field-width standard-round-box-curve" id="studentResponse" placeholder="Answer"/>
                      </div>
                 </div>
             

     <div className="col-md-12"> 
       <button id="" onClick={this.quizResponseUpdate}  className="btn btn-outline-success"> Submit </button>
     </div> 
     </div>
       
   </div>
              </div>
        
            </div>

   </div> 

   <div className="row bottom-right-curve black-background"> 


<div className="col-12 padding-off bottom-right-curve CodeMirror2"> 
 <div className="App row">
         
         <section className="playground col-12 col-md-6">
           <div className="code-editor html-code">
             <div className="editor-header">MIXED HTML</div>
             <CodeMirror4
               value={html}
               options={{
                 mode: 'htmlmixed',
                 ...codeMirrorOptions,
               }}
               onBeforeChange={(editor, data, html) => {

                 this.setState({ html });
               }}

               onChange={this.runCode}
               
             />
           </div>
         </section>
         <section className="result col-12 col-md-6 white-background padding-off">
           <iframe title="result" className="iframe" ref="iframe" />
         </section>
       </div>


           

</div>
</div>
 </div>

		)
}



}

export default editorQuizBAssessments